import React, { useEffect, useContext, useState } from "react";
import {
  Link,
  Datagrid,
  Dialog,
  Spinner,
  Icon,
  Button,
  Input,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import {
  ERROR_LIST,
  BASE_URL,
  BASE_URL_CAES,
  S3_BUCKET_DR_CAES
} from "../../strings";
import "./disturbance-measurements.css";

import NotificationExample from "../Notification/notification";
import { convertUtcToLocalString, decryptData } from "../../util";
import { getToken } from "../Authentication/get-token";

const postRequestUrlPrefix = BASE_URL_CAES;
function DisturbanceMeasurementData() {
  const {
    startTS,
    endTS,
    setdrmetrics,
    isnotify,
    drwidgetConfig,
    setdrwidgetConfig,
    setisnotify,
    totalDistRowCounts,
    settotalDistRowCounts,
  } = useContext(RootContext);

  const [isSpin, setispin] = useState(true);
  const [StreamUrl, setStreamUrl] = React.useState();
  const [error, setError] = useState(false);
  const [drAckStatus, setDrAckStatus] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState();
  const [data, setData] = React.useState([]);
  const [confirmDRAck, setConfirmDRAck] = useState(false);
  const [currentDrIndex, setcurrentDrIndex] = useState("");
  // CASE ML changes
  const [columnOrder, setColumnOrder] = React.useState(undefined);
  const [isDialogOpen, setisDialogOpen] = useState(false);
  const [sortOrder, setSortOrder] = React.useState({
    "field": "faultTime",
    "desc": true
  });
  const [fileName, setFileName] = useState("");
  const [utilData, setUtilData] = useState({
    fault_name: " ",
    should_patch: false,
  });
  const [isOthers, setisOthers] = useState(true);
  let probable_cause_val = "";
  const [exactCauses, setexactCauses] = React.useState([
    { exactcause1: "", exactcause2: "" },
  ]);
  const [probableCauses, setprobableCauses] = useState([
    { probablecause1: "", probablecause2: "", probablecause3: "" }
  ]);
  // added by Yash - start
  const [requestInferData, setRequestInferData] = useState(null);
  const [exactCausesGiven, setExactCausesGiven] = useState(null);
  const [currentStep, setcurrentStep] = useState("ANALYSIS_COMPLETE");
  const [loggedIsOperationalManager, setLoggedIsOperationalManager] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const initialColumns = React.useMemo(() => [
    {
      fieldKey: "s3Location", title: "FILE NAME", sortFn: (a, b, field) => {

        const valA = a.s3Location.props.rel;
        const valB = b.s3Location.props.rel;

        return valA < valB ? -1 : valA > valB ? 1 : 0;
      },
    },
    { fieldKey: "deviceId", title: "RELAY ID" },
    {
      fieldKey: "faultTime", title: "RECEIVED TIME"
    },
    {
      fieldKey: "action", title: "ACTIONS"
    },
    {
      fieldKey: "caesML", title: ""
    }
  ]);
  const [columns] = React.useState(initialColumns);

  useEffect(() => {
    if (utilData.should_patch) {
      patchUtil(utilData);
    }
  }, [utilData]);

  useEffect(() => {
    getApiData();
    fetchDRMetrics();
  }, [startTS]);

  useEffect(() => { }, [totalDistRowCounts]);

  const fetchDRMetrics = async () => {
    try {

      const requestBody = {

        substationList: JSON.parse(localStorage.getItem('selectedSubstationId'))

      };
      const response = await axios.post(
        BASE_URL +
        `substation-dr-metrics?startDateTime=${startTS}&endDateTime=${endTS}`,
        requestBody, getToken()
      );

      let fetchedMetrics = response.data;
      setdrmetrics(fetchedMetrics);
      setError(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
        } else if (status >= 500 && status < 600) {
          setError(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };

  const clearData = () => {
    setcurrentStep("ANALYSIS_COMPLETE");
    setisDialogOpen(false);
    setexactCauses([{ exactcause1: "", exactcause2: "" }]);
    setprobableCauses([{ probablecause1: "", probablecause2: "", probablecause3: "" }]);
  };
  const patchUtil = (utilData) => {
    //debugger;
    const postData = {
      "uri": utilData.uri,
      "fault_name": utilData.fault_name,
      "is_new_fault": utilData.is_new_fault,
      "probable_causes": utilData.probable_causes,
      "exact_causes": utilData.exact_causes
    }
    const postUrl = postRequestUrlPrefix + "util";
    const headers = {
      'Content-Type': 'application/json',
    };
    // Send the POST request using Axios
    // set cuyrrentStep to ANALYSIS_WAIT
    // setcurrentStep("ANALYSIS_WAIT");
    axios
      .post(postUrl, JSON.stringify(postData), {
        headers: headers,
      })
      .then((response) => {
        // console.log("DDD data", response);
        // Handle the response data here
        //debugger;
        console.log("Response data for util:", response.data);
        // debugger;
        // setResponseData(response.data[0]);
        // setcurrentStep("ANALYSIS_COMPLETE");
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };
  const getServe = async (requestData) => {
    const postData = {
      'uris': requestData.uris
    };
    // debugger;
    // Define the URL for your POST request
    const postUrl = postRequestUrlPrefix + "infer";
    const headers = {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*', // Required for CORS support to work
      // 'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
      // 'Accept': '*/*',
      // 'Connection': 'keep-alive',

      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      // "Access-Control-Allow-Headers":
      // "Content-Type, Authorization, X-Amz-Date, X-Api-Key, X-Amz-Security-Token",
      // "Access-Control-Allow-Credentials": "true",
      // "Access-Control-Max-Age": "*",
      // "Access-Control-Expose-Headers": "Content-Type",
    };
    // Send the POST request using Axios
    // set cuyrrentStep to ANALYSIS_WAIT
    setcurrentStep("ANALYSIS_WAIT");
    axios
      .post(postUrl, JSON.stringify(postData), {
        headers: headers,
      })
      .then((response) => {
        // Handle the response data here
        setResponseData(response.data[0]);
        setcurrentStep("ANALYSIS_COMPLETE");
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };
  const getExactCausesGiven = async (requestData) => {
    const postData = {
      'uris': requestData.uris,
      'should_calculate_exact_given': "True",
      'fault_name_arr_given': requestData.fault_name_arr
    };

    // debugger;
    // Define the URL for your POST request
    const postUrl = postRequestUrlPrefix + "infer";
    const headers = {
      'Content-Type': 'application/json',

    };
    // Send the POST request using Axios
    // set cuyrrentStep to ANALYSIS_WAIT
    setcurrentStep("NO_STEP_SHOW_EXACT_CAUSE_WAIT");
    axios
      .post(postUrl, JSON.stringify(postData), {
        headers: headers,
      })
      .then((response) => {
        console.log("DDD data", response);
        // Handle the response data here
        console.log("Response data for infer:", response.data);
        // debugger;
        setExactCausesGiven(response.data[0]);
        //debugger;
        setcurrentStep("NO_STEP_SHOW_EXACT_CAUSE");
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };

  const getApiData = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        BASE_URL +
        `dr-list?startDateTime=${startTS}&endDateTime=${endTS}&substationId=${localStorage.getItem("selectedSubstationId")}`, getToken()
      );
      let distrRecordsResp = response.data.body;

      settotalDistRowCounts(distrRecordsResp.length);
      setLoggedIsOperationalManager(JSON.parse(localStorage.getItem("USER_INFO"))?.role === "OperationalManager");
      const disturbanceRecordAcknowledgmentEnabled = JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.disturbanceRecordAcknowledgment;
      let formatedData = distrRecordsResp?.map((v, i) => ({
        fields: {
          s3Location: (
            <>

              <Link
                rel={v.s3Location}
                href=""
                onClick={() => handleDRfileLink(v.s3Location)}
                target="_blank"
                title="data"
              >
                <Icon
                  className="open-window"
                  name={"abb/" + "open-in-new-window"}
                  sizeClass="small"
                />
                <span className="dr-filename">{v?.s3Location ? v?.s3Location.split(".")[0] : ""} </span>
              </Link>

            </>
          ),
          deviceId: v.deviceId,
          //
          faultTime: convertUtcToLocalString(v?.faultTime.replace('T', ' ').substring(0, 16)),

          action: v.ack ? (
            <>
              <Icon name="abb/check-mark-circle-1" className="charged" />
              <span title="acknowledged">Acknowledged</span>
            </>
          ) : (

            <Button
              disabled={disturbanceRecordAcknowledgmentEnabled ? false : true}
              // id="ack-button-dr-list"
              icon="abb/check-mark-circle-2"
              sizeClass={"small"}
              type="ghost"
              title="acknowledge"
              text="Acknowledge"
              onClick={() => acknowledgeDRFile(v.s3Location, v.storageId)}
              shape={"pill"}
            />
          ),
          caesML: (
            <Button

              id="ack-button-setting"
              icon="abb/advanced-settings"
              sizeClass={"small"}
              type="ghost"
              title="CAES ML"
              text="CAES ML"
              onClick={() => {
                setRequestInferData({
                  fileName: v.s3Location,
                  relayName: v.site,
                })

                const configFileName = v?.s3Location.replace(".dat", ".cfg").replace(".DAT", ".CFG");
                const fileUri = configFileName;
                openDialogAndFetchMLOPData(fileUri);
                console.log();
              }}
              shape={"pill"}
            />


          )
        },
      }));

      setData(formatedData);
      setError(false);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };

  const getAnalysisInProgress = () => {
    return (
      <span>
        Analysis in progress for the DR file {requestInferData.fileName} in relay {requestInferData.relayName}
      </span>
    );
  };

  const getAnalysisComplete = () => {
    return <>
      <span>
        {/* <Icon
          className="charged"
          name="abb/check-mark-circle-2"
          sizeClass="medium"
        />{" "} */}
        {/* <span style={{ "vertical-align": "super" }}>
          Analysis Complete!
        </span> */}
      </span>
      <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} </span>
      <span>Trip type: {responseData.trip_check}</span>
      <span className="dr-reason">{responseData.predicted_fault_value}</span>

    </>
  };

  const getFaultIdentified = () => {
    return (
      <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} </span>
    );
  };

  const getProbableCause = () => {
    return (
      <>
        <span>
          {" "}
          Here are some of the probable causes of this type of fault.{" "}
        </span>
        <ol style={{ "text-align": "left" }}>
          {/* run map on responseData.probable_causes and return li */}
          {responseData.probable_causes.filter(cause => cause !== "").map((cause, index) => (
            <li key={index}>{cause}</li>
          ))}

        </ol>

        <span></span>
      </>
    );
  };

  const getExactCause = () => {
    // debugger;
    return (
      <>
        <span> Here are the exact causes of this type of fault. </span>
        <ol style={{ "text-align": "left" }}>
          {/* run map on responseData.exact_causes and return li */}
          {responseData.exact_causes.map((cause, index) => (
            <li key={index}>{cause}</li>
          ))}

        </ol>
      </>
    );
  };

  const openDialogAndFetchMLOPData = (fileUri) => {
    // do an axios post call here to https://glfycdjjk7.execute-api.ap-south-1.amazonaws.com/Demo/infer
    // with the fileUri as the request body 
    // and then set the response to the state variable requestInferData
    // debugger;
    getServe({ uris: [fileUri] }).then(() => {
      setisDialogOpen(true);
    });

  };



  function handleDRfileLink(fileName) {
    setisnotify("true");
    getAppStremUrl(fileName);
  }

  function getAppStremUrl(fileName) {

    let appsStreamURL =
      BASE_URL + `dr-generate?fileName=${fileName}&userId=${decryptData(localStorage.getItem('logged_in_user'))?.replace(/"/g, '')}`;
    //BASE_URL + `dr-generate?fileName=${fileName}&userId=456`;
    axios
      .get(appsStreamURL, getToken())
      .then((response) => {
        setisnotify("");
        window.open(response.data.url, "_blank");
        setispin(false);
      })
      .catch(function (error) {
        setisnotify("error");


      })
      .finally(() => { });
  }

  const acknowledgeDRFile = (filename, storageId) => {
    setdrwidgetConfig([{ ...drwidgetConfig[0], currentFile: filename }]);
    setFileName(filename);
    setConfirmDRAck(true);
    setcurrentDrIndex(storageId);
  };


  const approveAck = async () => {
    setConfirmDRAck(true);
    try {
      await axios.get(
        BASE_URL + `dr-doAck?storageId=${currentDrIndex}`, getToken()
      );

      setConfirmDRAck(false);
      setisnotify("dr-acked");
      fetchDRMetrics();
      getApiData();
      setTimeout(() => {
        setisnotify("");
        setcurrentDrIndex("");

      }, 3000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };
  return (
    <>
      {isDialogOpen && (
        <Dialog
          className="dialog-container"
          showCloseButton={true}
          closeOnEscape={true}
          closeOnLostFocus={true}
          hideBackground={true}
          onClose={() => {
            clearData();
          }}
          title="CAES ML Analysis"

          standardButtonsOnBottom={
            [
              // { text: 'Yes', type: 'primary-blue', handler: () => setisAnalysisComplete(true) },
              // { text: 'No', type: 'discreet-blue', handler: (dlg) => setisDialogOpen(false) },
              //(dlg) => setConfirmDRAck(false) && setDrAckStatus("Acknowledged") },
            ]
          }
        >
          <div className="dialog-content">
            {currentStep === "ANALYSIS_WAIT" && (
              <div className="dialog-section-1">
                <span>
                  Analysis in progress for the DR file {requestInferData.fileName} in relay {requestInferData.relayName}
                </span>
                <span> Please wait while we analyze the fault</span>
                <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
              </div>
            )
            }
            {currentStep === "ANALYSIS_COMPLETE" && (
              <div className="dialog-section-1">
                {getAnalysisComplete()}


                {loggedIsOperationalManager && <span> Does this fault look correct?</span>}

                {!loggedIsOperationalManager && <span>
                  <Link
                    onClick={() => {
                      setcurrentStep("SHOW_PROBABLE_CAUSE");
                    }}
                  >
                    View the Probable causes of this fault
                  </Link>
                </span>}
                <span>
                  {loggedIsOperationalManager && <Button
                    id="ack-button"

                    sizeClass={"small"}
                    type="ghost"
                    text="Yes"
                    onClick={() => {
                      setcurrentStep("SHOW_PROBABLE_CAUSE");
                    }}
                    shape={"pill"}
                  />}
                  {loggedIsOperationalManager &&
                    <Button
                      disabled={!JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.caesMLFeedback || JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.caesMLFeedback === "false"}
                      id="ack-button"
                      sizeClass={"small"}
                      type="ghost"
                      text="No"
                      onClick={() => {
                        // debugger;
                        setcurrentStep("NO_CASE_ANALYSIS");
                      }}
                      shape={"pill"}
                    />
                  }
                </span>
                <br />
                {/* {!JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.caesMLFeedback || JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.caesMLFeedback === "false" ? <p className="noteCaesML" >Note: CAES ML expert feedback input option is enabled only for the operation manager role.</p> : ""} */}
              </div>
            )}

            {currentStep === "NO_CASE_ANALYSIS" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                <span>We are sorry, you find the answer not correct</span>
                <br />
                <span>
                  Would you want to help us learn from the below options
                </span>
                <span>
                  {
                    Object.entries(responseData.fault_matches).map((fault) => (
                      <Button
                        id={`ack-button-${fault[0]}`}
                        sizeClass={"small"}
                        type="ghost"
                        onClick={() => {
                          // probable_cause_val = fault;
                          // setavailableMLFault(fault);
                          setisOthers(false);
                          setUtilData({
                            uri: requestInferData.fileName.replace(".dat", ".cfg").replace(".DAT", ".CFG"),
                            fault_name: `${fault[1]}`,
                            is_new_fault: false,
                            probable_causes: responseData.fault_causes[`${fault[1]}`],
                            exact_causes: [],
                            should_patch: false

                          });
                          // setcurrentStep("WRONG_ML_FAULT");
                        }}
                        text={`${fault[1]}`}
                        shape={"pill"}
                      />
                    ))}
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    onClick={() => {
                      setisOthers(true);
                      setUtilData({
                        uri: requestInferData.fileName.replace(".dat", ".cfg").replace(".DAT", ".CFG"),
                        fault_name: ' ',
                        is_new_fault: true,
                        probable_causes: [],
                        exact_causes: [],
                        should_patch: false

                      });
                      console.log("new fault console log", utilData);
                    }}

                    type="ghost"
                    text="Others"
                    shape={"pill"}
                  />
                </span>
                {/* show a shapn showing chosen fault name using utilData.fault_name */}
                <span>
                  {utilData.fault_name === " " ? "New fault chosen" : "The chosen fault name is " + utilData.fault_name}
                </span>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("ANALYSIS_COMPLETE");
                    }}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      isOthers
                        ? setcurrentStep("WRONG_ML_FAULT")
                        : setcurrentStep("NAME_WRONG_ML_FAULT");
                      console.log("isOthers:", isOthers);
                    }}
                  />
                </span>
              </div>
            )}
            {currentStep === "NAME_WRONG_ML_FAULT" && (
              <div className="dialog-section-2">
                <span>
                  <Icon
                    className="charged"
                    name="abb/check-mark-circle-2"
                    sizeClass="medium"
                  />{" "}
                  <span style={{ "vertical-align": "super" }}>
                    Analysis Complete!
                  </span>
                </span>
                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>
                <span> Thanks for your feedback!</span>
                <span>We're glad you find the answer correct</span>
                <br />
                <span>
                  {" "}
                  Here are some of the probable causes of this type of fault.{" "}
                </span>
                <ol style={{ "text-align": "left" }}>
                  {/* run map on responseData.probable_causes and return li */}
                  {utilData.probable_causes.filter(cause => cause !== "").map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))}

                </ol>

                <span></span>
                <span>
                  <Link
                    onClick={async () => {
                      await getExactCausesGiven({
                        uris: [requestInferData.fileName.replace(".dat", ".cfg").replace(".DAT", ".CFG")],
                        fault_name_arr: [utilData.fault_name]
                      });
                      // set util data
                      // debugger; 

                    }}
                  >
                    View the Exact causes of this fault
                  </Link>
                </span>
              </div>
            )}
            {currentStep === "NO_STEP_SHOW_EXACT_CAUSE_WAIT" && (
              <div className="dialog-section-1">
                <span>
                  Exact causes calculation in progress for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} for new fault {utilData.fault_name}
                </span>
                <span> Please wait while we calculate the new exact caauses</span>
                <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
              </div>
            )
            }
            {currentStep === "NO_STEP_SHOW_EXACT_CAUSE" && (
              <div className="dialog-section-2">
                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>
                {/* TODO */}
                <span>Here are the exact causes of this type of fault.</span>
                <ol style={{ "text-align": "left" }}>
                  {exactCausesGiven.exact_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))
                  }
                </ol>

                <span> Does this exact causes look correct?</span>
                <span>
                  <Button
                    id="ack-button"

                    sizeClass={"small"}
                    type="ghost"
                    text="Yes"
                    onClick={() => {
                      setcurrentStep("NO_STEP_SHOW_FINAL_CONFIRMATION");
                      setUtilData(
                        {
                          uri: requestInferData.fileName.replace(".dat", ".cfg").replace(".DAT", ".CFG"),
                          fault_name: utilData.fault_name,
                          is_new_fault: true,
                          probable_causes: utilData.probable_causes,
                          exact_causes: exactCausesGiven.exact_causes,
                          should_patch: true
                        }
                      );
                      // modify the button bg color to green

                    }}


                    shape={"pill"}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="No"
                    onClick={() => {
                      setcurrentStep("NO_STEP_GET_USER_INPUT_EXACT_CAUSE");
                    }}
                    shape={"pill"}
                  />
                </span>
              </div>
            )}

            {currentStep === "NO_STEP_SHOW_FINAL_CONFIRMATION" && (
              <div className="dialog-section-2">
                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>
                <span>Here are the exact causes of this type of fault.</span>
                <ol style={{ "text-align": "left" }}>
                  {exactCausesGiven.exact_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))
                  }
                </ol>
                <span>Great! thank you for your awesome feedback</span>
              </div>
            )}

            {currentStep === "NO_STEP_GET_USER_INPUT_EXACT_CAUSE" && (
              <div className="dialog-section-2">
                <div className="dialog-section-2">
                  <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                  <span className="dr-reason">{utilData.fault_name}</span>
                  <span></span>
                  <span>
                    Sorry for my limited knowledge. Help me learn the exact
                    cause of this disturbance record.
                  </span>
                  <span>Exact Cause 1 </span>
                  <Input
                    type="normal"
                    style={{
                      flex: "1",
                      minWidth: "160px",
                      width: "160px",
                      maxWidth: "160px",
                    }}
                    placeholder="Enter exact cause 1 here"
                    value={exactCauses[0].exactcause1}
                    //        [{ ...drwidgetConfig[0], currentFile: filename }]
                    onValueChange={(val) =>
                      setexactCauses([{ ...exactCauses[0], exactcause1: val }])
                    }

                  ></Input>

                  <span>Exact Cause 2</span>
                  <Input
                    type="normal"
                    style={{
                      flex: "1",
                      minWidth: "160px",
                      width: "160px",
                      maxWidth: "160px",
                    }}
                    placeholder="Enter exact cause 2 here"
                    value={exactCauses[0].exactcause2}
                    //        [{ ...drwidgetConfig[0], currentFile: filename }]
                    onValueChange={(val) =>
                      setexactCauses([{ ...exactCauses[0], exactcause2: val }])
                    }
                  ></Input>
                  <span>
                    <Button
                      id="ack-button"
                      sizeClass={"small"}
                      type="ghost"
                      text="Cancel"
                      onClick={() => {
                        setcurrentStep("NO_STEP_SHOW_EXACT_CAUSE");

                      }}
                      shape={"pill"}
                    />
                    <Button
                      id="ack-button"
                      sizeClass={"small"}
                      type="ghost"
                      text="Next"
                      onClick={() => {
                        setcurrentStep("NO_STEP_SHOW_FINAL_TRAIN_CONFIRMATION");
                        setUtilData({
                          uri: requestInferData.fileName.replace(".dat", ".cfg").replace(".DAT", ".CFG"),
                          fault_name: utilData.fault_name,
                          is_new_fault: utilData.is_new_fault,
                          probable_causes: utilData.probable_causes,
                          exact_causes: [exactCauses[0].exactcause1, exactCauses[0].exactcause2],
                          should_patch: true
                        });
                      }}
                      shape={"pill"}
                    />
                  </span>
                </div>
              </div>
            )}
            {currentStep === "NO_STEP_SHOW_FINAL_TRAIN_CONFIRMATION" && (
              <div className="dialog-section-2">
                {/* <span>
                  {" "}
                  <b>CASE ML Analysis </b>
                </span> */}
                <span>Great! thank you for your awesome feedback.</span>
                <span>
                  I have learned the exact causes shown below for this disturbance records.
                </span>
                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>

                <span>
                  <b>Exact causes</b>
                </span>
                <ol style={{ "text-align": "left" }}>
                  {utilData.exact_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))}
                </ol>
              </div>
            )}
            {currentStep === "WRONG_ML_FAULT" && (
              <div className="dialog-section-2">
                <span>
                  Sorry i have not been trained on this type of fault but from
                  my
                </span>
                <span>knowledge, i can tell the probable causes</span>
                <br />
                {getProbableCause()}
                <span>Please let us know the correct fault</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "160px",
                    maxWidth: "160px",
                    placeholder: "Enter value here",
                  }}
                  value={utilData.fault_name}
                  onValueChange={(val) =>
                    setUtilData({ ...utilData, fault_name: val, should_patch: false })
                  }
                ></Input>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("NO_CASE_ANALYSIS");
                    }}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      setcurrentStep("WRONG_ML_PROBABLE_CAUSE");
                    }}
                  />
                </span>
              </div>
            )}

            {currentStep === "WRONG_ML_PROBABLE_CAUSE" && (
              <div className="dialog-section-2">
                <span>
                  You can help me learn by entering the probable causes of this
                  new
                </span>
                <span>fault type one by one</span>
                <span>Probable Causes 1</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "160px",
                    maxWidth: "160px",
                    placeholder: "Enter value here",
                  }}
                  value={probableCauses[0].probablecause1}
                  onValueChange={(val) =>
                    setprobableCauses([{ ...probableCauses[0], probablecause1: val }])
                  }
                ></Input>
                <span>Probable Causes 2</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "160px",
                    maxWidth: "160px",
                    placeholder: "Enter value here",
                  }}
                  value={probableCauses[0].probablecause2}
                  onValueChange={(val) =>
                    setprobableCauses([{ ...probableCauses[0], probablecause2: val }])
                  }
                ></Input>
                <span>Probable Causes 3</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "160px",
                    maxWidth: "160px",
                    placeholder: "Enter value here",
                  }}
                  value={probableCauses[0].probablecause3}
                  onValueChange={(val) =>
                    setprobableCauses([{ ...probableCauses[0], probablecause3: val }])
                  }
                ></Input>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("WRONG_ML_FAULT");
                    }}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      setUtilData({
                        ...utilData,
                        probable_causes: [probableCauses[0].probablecause1, probableCauses[0].probablecause2, probableCauses[0].probablecause3],
                        should_patch: false
                      });
                      setcurrentStep("WRONG_ML_EXACT_CASUE");
                    }}
                  />
                </span>
              </div>
            )}

            {currentStep === "WRONG_ML_EXACT_CASUE" && (
              <div className="dialog-section-2">
                <span>
                  Now you can improve my knowledge by telling the exact
                </span>
                <span>
                  cause of this fault of the peobable cause you entered above.
                </span>
                <span>
                  Sorry for my limited knowledge. Help me learn exact cause of
                </span>
                <span>this disturbance record</span>

                <span>Exact Causes 1</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "160px",
                    maxWidth: "160px",
                    placeholder: "Enter value here",
                  }}
                  value={exactCauses[0].exactcause1}
                  onValueChange={(val) =>
                    setexactCauses([{ ...exactCauses[0], exactcause1: val }])
                  }
                ></Input>
                <span>Exact Causes 2</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "160px",
                    maxWidth: "160px",
                    placeholder: "Enter value here",
                  }}
                  value={exactCauses[0].exactcause2}
                  onValueChange={(val) =>
                    setexactCauses([{ ...exactCauses[0], exactcause2: val }])
                  }
                ></Input>

                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("WRONG_ML_PROBABLE_CAUSE");
                    }}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      setUtilData({
                        ...utilData,
                        uri: requestInferData.fileName.replace(".dat", ".cfg").replace(".DAT", ".CFG"),
                        is_new_fault: true,
                        exact_causes: [exactCauses[0].exactcause1, exactCauses[0].exactcause2],
                        should_patch: true
                      });
                      setcurrentStep("WRONG_ML_FEEDBACK_LAST");
                    }}
                  />
                </span>
              </div>
            )}
            {currentStep === "WRONG_ML_FEEDBACK_LAST" && (
              <div className="dialog-section-2">
                <span>Great! thank you for your awesome feedback.</span>
                <span>
                  I have learnt the exact causes shown below for this
                  disturbance
                </span>
                <span>records.</span>
                <br />

                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>
                <span> Thanks for your feedback!</span>
                <span>We're glad you find the answer correct</span>
                <br />
                <span>
                  {" "}
                  Probable causes{" "}
                </span>
                <ol style={{ "text-align": "left" }}>
                  {/* run map on responseData.probable_causes and return li */}
                  {utilData.probable_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))}

                </ol>
                <span>
                  {" "}
                  Exact causes{" "}
                </span>
                <ol style={{ "text-align": "left" }}>
                  {utilData.exact_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))}
                </ol>
              </div>
            )}

            {currentStep === "SHOW_PROBABLE_CAUSE" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                {loggedIsOperationalManager && <span> Thanks for your feedback!</span>}
                {loggedIsOperationalManager && <span> We're glad you find the answer correct </span>}
                {getProbableCause()}

                <span>
                  <Link
                    onClick={() => {
                      setcurrentStep("SHOW_EXACT_CAUSE");
                    }}
                  >
                    View the Exact causes of this fault
                  </Link>
                </span>
              </div>
            )}
            {currentStep === "SHOW_EXACT_CAUSE" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                {/* <span> Here are the exact causes of this type of fault. </span> */}
                {getExactCause()}
                {loggedIsOperationalManager && <span> Does this exact causes look correct?</span>}
                <span>
                  {loggedIsOperationalManager && <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Yes"
                    onClick={() => {
                      setcurrentStep("SHOW_FINAL_CONFIRMATION");
                    }}
                    shape={"pill"}
                  />
                  }
                  {loggedIsOperationalManager && <Button
                    id="ack-button"
                    disabled={!JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.caesMLFeedback || JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.caesMLFeedback === "false"}
                    sizeClass={"small"}
                    type="ghost"
                    text="No"
                    onClick={() => {
                      setcurrentStep("GET_USER_INPUT_EXACT_CAUSE");

                    }}
                    shape={"pill"}
                  />
                  }
                </span>
                <br />
                {/* {!JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.caesMLFeedback || JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.caesMLFeedback === "false" ? <p className="noteCaesML" >Note: CAES ML expert feedback input option is enabled only for the operation manager role.</p> : ""} */}
              </div>
            )}
            {currentStep === "SHOW_FINAL_CONFIRMATION" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                <span> Here are the exact causes of this type of fault. </span>
                <ol style={{ "text-align": "left" }}>
                  <li> Fault wiring</li>
                  <li>Insulation failure</li>
                  <li>Lightning strikes</li>
                </ol>
                <span>Great! thank you for your awesome feedback</span>
              </div>
            )}
            {currentStep === "GET_USER_INPUT_EXACT_CAUSE" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                <span>
                  Sorry for my limited knowledge. Help me learn the exact cause
                  of this disturbance record.
                </span>
                <span>Exact Cause 1 </span>
                {/* <Dropdown
                  className="filter-dropdown col-s-12"
                  label="Time Period"
                  placeholder={selectedSingle ? selectedSingle : "Last 24 Hours"}
                  monochrome={false}
                  value={selectedSingle}
                  onChange={getFilterTime}
                >
                  {filterOptions.map((o, i) => (
                    <DropdownOption key={o.title} label={o.title} value={o.value} />
                  ))}
                </Dropdown> */}
                <Input
                  type="normal"
                  dataType="text"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "160px",
                    maxWidth: "160px",
                    placeholder: "Enter exact cause 1 here",
                  }}
                  value={exactCauses[0].exactcause1}
                  //        [{ ...drwidgetConfig[0], currentFile: filename }]
                  onValueChange={(val) =>
                    setexactCauses([{ ...exactCauses[0], exactcause1: val }])
                  }
                ></Input>

                <span>Exact Cause 2</span>

                <Input
                  type="normal"
                  dataType="text"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "160px",
                    maxWidth: "160px",
                    placeholder: "Enter exact cause 2 here",
                  }}
                  value={exactCauses[0].exactcause2}
                  //        [{ ...drwidgetConfig[0], currentFile: filename }]
                  onValueChange={(val) =>
                    setexactCauses([{ ...exactCauses[0], exactcause2: val }])
                  }
                ></Input>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("SHOW_EXACT_CAUSE");
                    }}
                    shape={"pill"}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      setcurrentStep("SHOW_FINAL_TRAIN_CONFIRMATION");
                      setUtilData({
                        uri: requestInferData.fileName.replace(".dat", ".cfg").replace(".DAT", ".CFG"),
                        fault_name: responseData.predicted_fault_value,
                        is_new_fault: false,
                        probable_causes: responseData.probable_causes,
                        exact_causes: [exactCauses[0].exactcause1, exactCauses[0].exactcause2],
                        should_patch: true

                      });
                    }}
                    shape={"pill"}
                  />
                </span>
              </div>
            )}
            {currentStep === "SHOW_FINAL_TRAIN_CONFIRMATION" && (
              <div className="dialog-section-2">
                {/* <span>
                  {" "}
                  <b>CASE ML Analysis </b>
                </span> */}

                {getAnalysisComplete()}

                <span>
                  <b>Exact causes</b>
                </span>
                <ol style={{ "text-align": "left" }}>
                  <li> {exactCauses[0].exactcause1}</li>
                  <li>{exactCauses[0].exactcause2}</li>
                </ol>

                <span>Great! thank you for your awesome feedback.</span>
                <span>
                  I have learned the exact causes shown below for this
                  disturbance records.
                </span>
              </div>
            )}
            {/* {isAnalysisComplete && <div className="">

            <div>
            </div>
            } */}
            {/* {isAnalysisFinish && <div className="">Analysis Complete</div>} */}
            {/* {isAnalysisComplete && <div className="">Analysis Finsished</div>} */}
          </div>
        </Dialog>
      )}
      {isnotify == "dr-acked" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}

      {confirmDRAck && (
        <div className="overlay-dr">
          <div className="dialog-container-dr-ack">


            <Dialog
              className="dr-confim-ack"

              hideBackground={true}
              dimBackground={true}
              showCloseButton={false}
              closeOnEscape={false}
              closeOnLostFocus={false}

              onClose={() => setConfirmDRAck(false)}


              title="Confirm Acknowledge"
              standardButtonsOnBottom={[
                {
                  text: "No",
                  type: "discreet-blue",
                  handler: (dlg) => setConfirmDRAck(false),
                },
                {
                  text: "Yes",
                  type: "primary-blue",
                  handler: (dlg) => approveAck(),
                },
              ]}
            >
              <div>Are you sure you want to acknowledge : {fileName}</div>
            </Dialog>
          </div>
        </div>
      )}
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          sizeClass="small"
        />
      ) : (
        <>
          {error ? (
            <span
              style={{ margin: "auto" }}
              color="dark-grey"
              sizeClass="small"
            >
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  getApiData();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data.length > 0 ? (
                <Datagrid
                  id="dr-list-table"
                  className="table-style"
                  data={data}
                  columns={data.length > 0 ? columns : ""}
                  pageSize={pageSize}
                  page={currentPage}
                  rowHeight="small"
                  enableSorting={true}
                  sortOrder={sortOrder}
                  onSort={(so) => { if (so) { } }}
                />
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_RECORDS}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default DisturbanceMeasurementData;
